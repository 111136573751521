export const faqData = [
	{
		top: 'When do I get access to the app?',
		bottom: 'You get access to the app right after the payment.',
	},
	{
		top: 'What if I don’t receive the email?',
		bottom: 'We highly value our customers and we are always here to help with any issue. If such problem appears, check your spam folder first. Although you can always access your guide from the site, our support team is glad to resend you another email if needed.',
	},
	{
		top: 'How does trial work?',
		bottom: 'We offer a 3 days trial subscription for service. Unless you cancel at least 24 hours before the end of the free trial, you will be automatically charged a price indicated on the payment screen for a chosen subscription period.',
	},
	{
		top: 'Cancel subscription',
		bottom: (
			<>
				If you purchased a subscription or enabled trial on App Store:
				<br />
				You can cancel a trial or a subscription anytime by turning-off
				autorenewal through your Apple ID account settings. To avoid being
				charged, cancel the subscription in your Apple ID account settings at
				least 24 hours before the end of the trial or then-current subscription
				period. You alone can manage your subscriptions. Learn more about managing
				subscriptions (and how to cancel them) on{' '}
				<a href='/'>Apple support page</a>.
				<br />
				<br />
				If you purchased a subscription or enabled trial on Google Play:
				<br />
				You can cancel a trial or a subscription anytime by turning off
				auto-renewal through your Google Play account settings. To avoid being
				charged, cancel the subscription in your account settings at least 24
				hours before the end of the trial or then-current subscription period. You
				alone can manage your subscriptions. Learn more about managing
				subscriptions (and how to cancel them) on{' '}
				<a href='/'>Google’s support page</a>.
				<br />
				<br />
				If you purchased a subscription or enabled trial on our website: <br />
				You can cancel a trial or a subscription by contacting our support{' '}
				<a href='/'>support@hwteam.co</a> at least 24 hours before the end of the
				trial or then-current subscription period. Also, you may enter My Account
				and cancel subscription on your own.
			</>
		),
	},
	{
		top: 'How can I request a refund?',
		bottom: (
			<>
				If you made a purchase directly on our website and the money-back option
				was presented to you during the checkout, you are eligible to receive a
				refund provided that all of the following conditions are met. You contact
				us within 14 days after your initial purchase and before the end of your
				subscription period; and you are able to demonstrate that you have used
				the app pursuant to the requirements stated below in Section “How to
				demonstrate that you have used the app”. We will review your application
				and notify you (by email) whether your application is approved. Please
				refer to our <a href='/'>Money-Back Policy.</a>
			</>
		),
	},
]
