import { createSlice } from '@reduxjs/toolkit'

export const STATE_KEY = 'user'

const initialState = {
	name: 'aaa',
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action) => {
			state.name = action.payload
		},
	},
})

export const { setUser } = userSlice.actions

export const getUser = state => {
	console.log(state.user.name)
	if (state.user.name === 'aa') {
		return 'hello'
	}
	return 'ska'
}
export const getName = state => {
	if (state.user.name === 'aa') {
		return 'Andriy'
	}
	if (state.user.name === 'bb') {
		return 'Andriy'
	}
	return 'ska'
}

export const userReducer = userSlice.reducer
