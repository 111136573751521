import React from 'react'

import './styles.scss'

const Footer = () => {
	return (
		<footer className='footer'>
			<div className='footer__wrapper '>
				GM Unicorn Corporation Limited Florinis 7, Greg Tower,2nd Floor, 1065,
				Nicosia, Cyprus
			</div>
		</footer>
	)
}

export default Footer
