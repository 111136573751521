import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination } from 'swiper/core'

import 'swiper/components/pagination/pagination.min.css'
import 'swiper/swiper.min.css'
import './styles.scss'

SwiperCore.use([Pagination])

const Products = () => {
	return (
		<div className='products'>
			<div className='products__wrapper'>
				<div className='products__product'>
					<div className='product__wrapper'>
						<div className='product__info'>
							<img
								className='product__icon'
								src='/images/refine.png'
								alt='refine'
							/>
							<div className='product__right'>
								<h1 className='product__title'>
									Refine: Video &<br /> Photo Editor
								</h1>
								<div className='product__rating'>
									4.2{' '}
									<img
										className='product__rating-icon'
										src='/images/refine-rating.png'
										alt='refine-rate'
									/>
								</div>
								<p className='product__comments'>2.6K Ratings</p>
							</div>
						</div>
						<div className='product__platforms'>
							Available in App Store/Google Play.
						</div>
					</div>
					<div className='product__slider'>
						<Swiper
							spaceBetween={15}
							slidesPerView={'auto'}
							pagination={true}
						>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/refine-slide1.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/refine-slide2.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/refine-slide3.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/refine-slide4.png'
									alt='slide'
								/>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
				<div className='products__product'>
					<div className='product__wrapper'>
						<div className='product__info'>
							<img
								className='product__icon'
								src='/images/gethabit.png'
								alt='refine'
							/>
							<div className='product__right'>
								<h1 className='product__title'>
									GetHabit: Motivation <br />& Plannere
								</h1>
								<div className='product__rating'>
									4.3{' '}
									<img
										className='product__rating-icon'
										src='/images/gethabit-rating.png'
										alt='gethabit-rate'
									/>
								</div>
								<p className='product__comments'>6.5K Ratings</p>
							</div>
						</div>
						<div className='product__platforms'>
							Available in App Store/Google Play.
						</div>
					</div>
					<div className='product__slider'>
						<Swiper
							spaceBetween={15}
							slidesPerView={'auto'}
							pagination={true}
						>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/gethabit-slide1.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/gethabit-slide2.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/gethabit-slide3.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/gethabit-slide4.png'
									alt='slide'
								/>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
				<div className='products__product'>
					<div className='product__wrapper'>
						<div className='product__info'>
							<img
								className='product__icon'
								src='/images/widgetbox.png'
								alt='refine'
							/>
							<div className='product__right'>
								<h1 className='product__title'>
									WidgetBox: Widgets <br />
									for iPhone
								</h1>
								<div className='product__rating'>
									4.6{' '}
									<img
										className='product__rating-icon'
										src='/images/widgetbox-rating.png'
										alt='widgetbox-rate'
									/>
								</div>
								<p className='product__comments'>43.8K Ratings</p>
							</div>
						</div>
						<div className='product__platforms'>
							Available in App Store/Google Play.
						</div>
					</div>
					<div className='product__slider'>
						<Swiper
							spaceBetween={15}
							slidesPerView={'auto'}
							pagination={true}
						>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/widgetbox-slide1.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/widgetbox-slide2.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/widgetbox-slide3.png'
									alt='slide'
								/>
							</SwiperSlide>
							<SwiperSlide>
								<img
									className='product__slider-item'
									src='/images/slider/refine-slide4.png'
									alt='slide'
								/>
							</SwiperSlide>
						</Swiper>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Products
