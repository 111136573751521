import React, { useState, useEffect } from 'react'

import Navbar from '../../../components/Navbar'
import Products from '../components/Products'
import Faq from '../components/Faq'
import Footer from '../components/Footer'

import './styles.scss'

const ANIMATION_TIMEOUT = 300

const Home = () => {
	const [isActive, setIsActive] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setIsActive(true)
		}, ANIMATION_TIMEOUT)
	}, [])

	const onContactBtnClick = () => {
		if (window?.zE) {
			window?.zE('webWidget', 'open')
		}
	}

	return (
		<div className={`home ${isActive ? 'home--active' : ''}`}>
			<Navbar onBtnClick={onContactBtnClick} />
			<Products />
			<Faq />
			<Footer />
		</div>
	)
}

export default Home
