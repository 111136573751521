import React from 'react'

import './styles.scss'

const Navbar = ({ onBtnClick }) => {
	return (
		<nav className='navbar'>
			<div className='navbar__wrapper'>
				<button className='navbar__btn-home' onClick={onBtnClick}>
					<img
						className='navbar__message-ico'
						src='/images/message.png'
						alt='message'
					/>
					Contact Us
				</button>
			</div>
		</nav>
	)
}

export default Navbar
