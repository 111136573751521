import React, { useState } from 'react'

import './styles.scss'

import { faqData } from '../../../../model/faqData'

const Faq = () => {
	const [activePanels, setActivePanels] = useState([])

	const handlePanelClick = index => {
		if (activePanels.includes(index)) {
			setActivePanels(activePanels.filter(elem => elem !== index))
			return
		}
		setActivePanels([...activePanels, index])
	}

	const getActivePanel = index => activePanels.includes(index)

	return (
		<div className='faq'>
			<div className='faq__wrapper'>
				<h2 className='faq__title'>FAQ</h2>
				<div className='faq__items'>
					{faqData.map((elem, index) => (
						<div className='faq__item' key={index}>
							<div
								className='faq__item-top'
								onClick={() => {
									handlePanelClick(index)
								}}
							>
								{elem.top}
								<div className='faq__item-icon'>
									<img
										className={`faq__item-plus ${
											getActivePanel(index)
												? 'faq__item-plus--active'
												: ''
										}`}
										src='/images/plus.png'
										alt='plus'
									/>
								</div>
							</div>

							<div
								className={`faq__item-bottom ${
									getActivePanel(index)
										? 'faq__item-bottom--opened'
										: 'faq__item-bottom--closed'
								}`}
							>
								<div className='faq__item-bottom-text '>
									{elem.bottom}
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default Faq
