import React from 'react'
import Router from './router'

function App() {
	return (
		<div className='app'>
			<Router />
		</div>
	)
}

export default App
